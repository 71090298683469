import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { GET_WORKFLOW_STATISTICS } from '../../constants/constants'
import { fetchstatisticsByWorkflowIdFailure, fetchstatisticsByWorkflowIdStart, fetchstatisticsByWorkflowIdSuccess } from "./reducer";

export const getStatisticsByWorkflowId = (workflowId, workflow) => (dispatch) => {
    dispatch(fetchstatisticsByWorkflowIdStart())

    determineBaseUrlAndPerformApiCall(`${GET_WORKFLOW_STATISTICS}${workflowId}`, 'GET')
        .then((response) => {
            if (workflow && Array.isArray(workflow.tasks)) {
                const bundleTask = workflow?.tasks?.find(task => task?.key_name === "create_bundle")

                if (bundleTask?.result && bundleTask?.result !== null) {
                    const statistics = Array.isArray(bundleTask?.result) ? bundleTask?.result : [bundleTask?.result]
                    dispatch(fetchstatisticsByWorkflowIdSuccess(statistics));
                } else {
                    const matchingTask = response
                        ?.filter(item => item?.task_id === bundleTask?.id && item?.result)

                    const statsResult = matchingTask.map(item => Array.isArray(item?.result) ? item?.result : [item?.result])

                    if (statsResult.length > 0) {
                        dispatch(fetchstatisticsByWorkflowIdSuccess(statsResult));
                    }
                }
            }

        })
        .catch((errorResp) => { dispatch(fetchstatisticsByWorkflowIdFailure(errorResp.message)); })
}