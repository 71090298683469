import { DateTime } from "ts-luxon"

// Utility function for formatting dates with optional parameters for custom formats
export const formatDate = (dateInput: string | Date, formatType: 'MM-DD-YYYY' | 'Month DD, YYYY' = 'MM-DD-YYYY'): string => {
    const date = typeof dateInput === 'string' ? new Date(dateInput) : dateInput
    if (formatType === 'MM-DD-YYYY') {
        const month = (date?.getMonth() + 1)?.toString()?.padStart(2, '0')
        const day = date?.getDate()?.toString()?.padStart(2, '0')
        const year = date?.getFullYear()
        return `${month}-${day}-${year}`
    } else {
        return date?.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: '2-digit'
        })
    }
}

// Utility function for formatting day with capitalization
export const formatDay = (date: Date): string => {
    const etDate = new Date(date?.toLocaleString("en-US", { timeZone: "America/New_York" }))
    return etDate?.toLocaleDateString('en-US', { weekday: 'long' })
}

// Utility function to format time in AM/PM format
export const formatTime = (dateInput: string | Date): string => {
    const date = typeof dateInput === 'string' ? new Date(dateInput) : dateInput
    let hours = date?.getHours()
    const minutes = date?.getMinutes()?.toString()?.padStart(2, '0')
    const ampm = hours >= 12 ? 'pm' : 'am'
    hours = hours % 12 || 12
    return `${hours}:${minutes} ${ampm}`
}

// Utility function to calculate time differences in hh:mm:ss format
const formatDuration = (totalSeconds: number): string => {
    const hours = Math.floor(totalSeconds / 3600)
    const minutes = Math.floor((totalSeconds % 3600) / 60)
    const seconds = Math.floor(totalSeconds % 60)
    return `${hours}h:${minutes}m:${seconds}s`
}

// Function to calculate the remaining time in UTC
export const calculateRemainingTime = (startTimeStr: string, allocatedTimeInSeconds: number): string => {
    const etTime = DateTime?.fromISO(startTimeStr, { zone: 'America/New_York' }).toUTC()
    const secondsDifference = DateTime?.utc().diff(etTime, 'seconds').seconds
    const remainingTimeInSeconds = Math.max(0, allocatedTimeInSeconds - secondsDifference)
    return formatDuration(remainingTimeInSeconds)
}

// Utility function to calculate elapsed time since a given start time
export const calculateElapsedTime = (startTimeStr: string): string => {
    if (!startTimeStr){ return ""}
    const etTime = DateTime.fromISO(startTimeStr, { zone: 'America/New_York' }).toUTC()
    const secondsDifference = DateTime?.utc()?.diff(etTime, 'seconds').seconds
    return formatDuration(Math.max(0, secondsDifference))
}
