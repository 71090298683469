import React from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { TransactionList } from "./Listing";
import { FormatName } from "helpers/Formatters/Format";
import { TableContainer } from "./TableContainer";
import Button from "Components/Common/Button";
import { useNavigate } from "react-router-dom";
// import TableContainer from "Components/Common/TableContainerReactTable";

export const TransactionDetails = ({ transDetails, type, currentPageNumber,setCurrentPageNumber }: any) => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Row>
        <Col className="">
          {/* <TableContainer /> */}
          <Card className="border-1 border-gray-200">
            <CardBody className="p-0">
              <TableContainer
                summary={transDetails?.inbound_day_summary}
                type={type}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card className="border-2 border-gray-200">
            <CardHeader>
              <h3 className="card-title mb-0 uppercase">
                Transaction Type - {FormatName(type)}
              </h3>
            </CardHeader>
            <CardBody className="p-0">
              <TransactionList
                transactionList={transDetails?.transactions}
                pageLimit={transDetails?.pagination?.total_pages}
                currentPageNumber={currentPageNumber}
                setCurrentPageNumber={setCurrentPageNumber}
              />
            </CardBody>
            <div className="m-2">
            <Button onClick={() => navigate(-1)} label="Back" styleType="primary" />
            </div>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};
