import React, { Fragment } from "react"
import { Table } from "reactstrap"
import { INBOUND_ACH } from "slices/constants/constants"

export const TableContainer = ({ summary, type }: any) => {
  return (
    <Fragment>
      <div className="table-responsive rounded-md">
        <div>
          <Table
            className={"table-borders table-nowrap align-middle mb-0"}
          >
            <thead>
              <tr>
                <th colSpan={6}>
                  <p className="p-0 m-0 text-sm uppercase text-gray-700">
                    Inbound Transaction ID: {summary?.inbound_transaction_id}
                  </p>
                </th>
              </tr>
              <tr className="text-center bg-[#F4F3F2]">
                <th></th>
                <th colSpan={2} className="border-gray-400">
                  Trustee Portion
                </th>
                <th colSpan={2}>Fee Portion</th>
                <th>Total Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="font-bold">Original Inbound</td>
                <td colSpan={2} className="text-right">
                  {summary?.original_inbound?.trustee_portion}
                </td>
                <td colSpan={2} className="text-right">
                  {summary?.original_inbound?.fee_portion}
                </td>
                <td className="text-right text-secondary font-bold">
                  {summary?.original_inbound?.total_amount}
                </td>
              </tr>
              {type === INBOUND_ACH && (
                <>
                  <tr>
                    <td className="font-bold">Returns</td>
                    <td colSpan={2} className="text-right">
                      {summary?.returns?.trustee_portion}
                    </td>
                    <td colSpan={2} className="text-right">
                      {summary?.returns?.fee_portion}
                    </td>
                    <td className="text-right text-secondary font-bold">
                      {summary?.returns?.total_amount}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold">Untimely Returns</td>
                    <td colSpan={2} className="text-right">
                      {summary?.untimely_returns?.trustee_portion}
                    </td>
                    <td colSpan={2} className="text-right">
                      {summary?.untimely_returns?.fee_portion}
                    </td>
                    <td className="text-right text-secondary font-bold">
                      {summary?.untimely_returns?.total_amount}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold">Inbound After Returns</td>
                    <td colSpan={2} className="text-right">
                      {summary?.inbound_after_returns?.trustee_portion}
                    </td>
                    <td colSpan={2} className="text-right">
                      {summary?.inbound_after_returns?.fee_portion}
                    </td>
                    <td className="text-right text-secondary font-bold">
                      {summary?.inbound_after_returns?.total_amount}
                    </td>
                  </tr>
                </>
              )}
              <tr className="bg-[#F4F3F2]">
                <td rowSpan={2} className="font-bold bg-white rounded-bl-sm">
                  Disposition
                </td>
                <td className="font-bold text-center">Disbursed</td>
                <td className="font-bold text-center">To be Distributed</td>
                <td className="font-bold text-center">Disbursed</td>
                <td className="font-bold text-center">To be Distributed</td>
                <td rowSpan={2} className="bg-white rounded-br-sm"></td>
              </tr>
              <tr className="text-right">
                <td>{summary?.disposition?.trustee_portion?.disbursed}</td>
                <td>
                  {summary?.disposition?.trustee_portion?.to_be_disbursed}
                </td>
                <td>{summary?.disposition?.fee_portion?.disbursed}</td>
                <td>{summary?.disposition?.fee_portion?.to_be_disbursed}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </Fragment>
  )
}
