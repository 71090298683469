import { Modal, Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

export const SessionTimeOut = () => {
    const handleOkClick = () => {
        window.location.href = "/";
    }
    
    return (
        <Modal centered={true} isOpen={true} >
            <ModalHeader>
                Session Timeout
            </ModalHeader>
            <ModalBody className="d-flex justify-content-start align-items-start">
                Kindly login again
            </ModalBody>
            <ModalFooter className="d-flex justify-content-end">
                <Button color="secondary" onClick={handleOkClick} >OK</Button>
            </ModalFooter>
        </Modal>
    )
}
