import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// import reusable components
import { Card, CardBody, Col, Container, Row, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import BreadCrumb from '../../Components/Common/BreadCrumb'
import classnames from 'classnames'
import { ToastContainer } from 'react-toastify'
import "react-toastify/dist/ReactToastify.css"
// import page components
import { ActionList } from './ActionList'
import { ActiveActionList } from './ActiveActionsList'
// Redux for workflows
import { fetchActions } from 'slices/Actions/thunk'
import { fetchWorkflows } from 'slices/workflow/thunk'
// import utilities
import { calculateElapsedTime } from 'helpers/Formatters/DateFormatter'
import { getStatisticsByWorkflowId } from 'slices/workflow/statistics/thunk'
import { handleStartButtonClick, handleTicketEsclations } from 'helpers/Workflow/helper'
import { Slider } from 'Components/Common/Slider/SliderLayout'
import { TaskActionContent } from 'Components/Common/Slider/Content/TaskActionContent'
import { TaskEscalationContent } from 'Components/Common/Slider/Content/TaskEscalationContent'
import { useNavigate } from 'react-router-dom'
import { separateActionsByStatus } from 'helpers/Formatters/Format'

const Action = () => {
    document.title = "Actions | TFS Processing App"

    const dispatch = useDispatch()

    const { actions, loadingActions } = useSelector((state) => state.Action)
    const { workflows } = useSelector((state) => state.Workflow)
    const { statistics, statisticsLoading } = useSelector((state) => state.Statistics)

    const ticketTitle = useRef(null)
    const ticketSubject = useRef(null)

    const [activeTab, setActiveTab] = useState('1')
    const [openTab, setOpenTab] = useState({ open: false, disabled: false })
    const [openEscalation, setOpenEscalation] = useState({ open: false, disabled: false })
    const [tasks, setTasks] = useState([])
    const [stats, setStats] = useState([])
    const [pendingActions, setPendingActions] = useState([])
    const [completedActions, setCompletedActions] = useState([])
    const [failedActions, setFailedActions] = useState([])

    const toggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
    }

    const getWorkflowById = (workflows, targetId) => workflows?.find((workflow) => workflow?.id === targetId)

    useEffect(() => {
        // We call get actions on page load
        dispatch(fetchActions())
        // We call get workflows on page load
        dispatch(fetchWorkflows())
        setOpenTab({ open: false, disabled: true })
    }, [])

    useEffect(() => {
        if (openTab?.open) {
            // Get Task ID
            if (openTab.taskKeyName !== "create_bundle") {
                // Workflow
                const targetId = openTab?.workflowId
                const matchingWorkflow = getWorkflowById(workflows, targetId)
                setTasks(matchingWorkflow?.tasks)
                dispatch(getStatisticsByWorkflowId(targetId, matchingWorkflow))
            }
        }
    }, [openTab])

    useEffect(() => {
        if (statistics && !statisticsLoading) {
            setStats(statistics)
        }
    }, [statistics, statisticsLoading])

    useEffect(() => {
        // Whenever workflow data is changes, update the display data
        if (actions && !loadingActions) {
            const { completed, pending, failed } = separateActionsByStatus({ workflows, actions })

            setCompletedActions(completed)
            setPendingActions(pending)
            setFailedActions(failed)

            const timerId = setInterval(() => {
                if (pending) {
                    // Calculate time elapsed for the pending time
                    // Workflow's Startime and Current time subtraction
                    const updatedPending = pending?.map((action) => {
                        // Calculate and return a new object with the updated timeElapsed
                        return {
                            ...action,  // Copy all other properties of the action
                            timeElapsed: calculateElapsedTime(action?.createdAt)  // Add the updated timeElapsed
                        }
                    })

                    setPendingActions(updatedPending)
                }
            }, 1000)

            return () => clearInterval(timerId)
        }

    }, [actions, loadingActions])

    const handleEscalate = () => {
        setOpenTab({ open: false })
        setOpenEscalation({
            open: true,
            id: openTab.taskId,
            taskKeyName: openTab?.actionKeyName,
            esclationUrl: openTab?.esclationUrl,
            actionName: openTab?.actionName,
        })
    }

    const navigate = useNavigate()
    const handleConfirm = () => {
        handleStartButtonClick({
            taskId: openTab.taskId,
            actionPath: openTab.actionPath,
            actionKeyName: openTab.taskKeyName,
            workflowId: openTab.workflowId,
            setOpenTab: setOpenTab,
            workflows: workflows,
            toNavigate: true
        }, navigate)
    }

    const handleTicketConfirmation = () => {
        handleTicketEsclations({
            taskId: openEscalation?.id,
            actionKeyName: openEscalation?.taskKeyName,
            setOpenTab: setOpenTab,
            setOpenEscalation: setOpenEscalation,
            ticketTitle: ticketTitle,
            ticketSubject: ticketSubject,
            workflowId: setOpenEscalation?.workflowId,
            workflows: workflows
        })
    }

    return (
        <React.Fragment>
            <ToastContainer />
            {loadingActions ? (
                <div className="loader-container">
                    <div className="loader"></div>
                </div>
            ) : (<div className="page-content relative" id="processingContent">

                {/* Slider for Ticket Escalation */}
                <Slider isOpen={openEscalation.open} setOpen={setOpenEscalation} actionName={openEscalation.actionName}>
                    <TaskEscalationContent
                        ticketTitle={ticketTitle}
                        ticketSubject={ticketSubject}
                        setOpenEscalation={setOpenEscalation}
                        handleTicketConfirmation={handleTicketConfirmation}
                    />
                </Slider>

                {/* Slider for Manual Action */}
                {openTab?.open &&
                    (<Slider isOpen={openTab.open} setOpen={setOpenTab} actionName={openTab.actionName}>
                        <TaskActionContent
                            openTab={openTab}
                            setOpenTab={setOpenTab}
                            stats={stats}
                            handleEscalate={handleEscalate}
                            handleConfirm={handleConfirm}
                            tasks={tasks}
                            workflowId={openTab.workflowId}
                        />
                    </Slider>)}

                <Container fluid>
                    {/* Page Heading */}
                    <BreadCrumb title="Action List" pageTitle="Action summary" />

                    {/* Action Items */}
                    <Row className='relative'>
                        <Col>
                            <Card>
                                <CardBody>

                                    {/* Action Tabs */}
                                    <Nav tabs className="nav-tabs-custom nav-success mb-3">
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === '1' })}
                                                onClick={() => toggle('1')}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                PENDING
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === '2' })}
                                                onClick={() => toggle('2')}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                COMPLETED
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === '3' })}
                                                onClick={() => toggle('3')}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                FAILED
                                            </NavLink>
                                        </NavItem>
                                    </Nav>

                                    {/* Action Tab Items */}
                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId="1">
                                            <ActiveActionList actionsData={pendingActions} setOpenTab={setOpenTab} />
                                        </TabPane>
                                        <TabPane tabId="2">
                                            <ActionList actionsData={completedActions} />
                                        </TabPane>
                                        <TabPane tabId="3">
                                            <ActionList actionsData={failedActions} />
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>)}
        </React.Fragment>
    )
}

export default Action