import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// import redux for workflows
import { fetchWorkflows } from 'slices/workflow/thunk'
// import utilities
import {
    separateWorkflowsByStatus
} from 'helpers/Formatters/Format'
// import resusable components
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'
import BreadCrumb from '../../Components/Common/BreadCrumb'
// import page components
import { WorkflowList } from './WorkflowList'
import { WorkflowHistory } from './WorkflowHistory'

const Workfow = () => {
    document.title = "Workflow | TFS Processing App"

    const dispatch = useDispatch()
    // Workflow context variable
    const { workflows, loading } = useSelector((state) => state.Workflow)

    const [completedWorkflows, setCompletedWorkflows] = useState([])
    const [inProgressWorkflows, setInProgressWorkflows] = useState([])

    useEffect(() => {
        // We call get workflows on page load
        dispatch(fetchWorkflows())
    }, [])

    useEffect(() => {
        // Whenever workflow data is changes, update the display data
        if (workflows && !loading) {
            const { completed, inProgress } = separateWorkflowsByStatus(workflows)

            setCompletedWorkflows(completed)
            setInProgressWorkflows(inProgress)
        }
    }, [loading, workflows])

    return (
        <React.Fragment>
            {/* Display loader if workflows are yet to be fetched from the API */}
            {loading ? (
                <div className="loader-container">
                    <div className="loader"></div>
                </div>
            ) :
                (<div className="page-content" id="processingContent">
                    <Container fluid>
                        {/* Page Heading */}
                        <BreadCrumb title="Workflow" pageTitle="Workflow" action="/workflow" />
                        {/* Active Workflow List */}
                        <Row>
                            <Col>
                                <Card>
                                    <CardHeader>
                                        <h3 className="card-title mb-0 capitalize">ACTIVE WORKFLOWS</h3>
                                    </CardHeader>
                                    <CardBody>
                                        <WorkflowList workflowData={inProgressWorkflows} />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        {/* Workflow History */}
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardHeader>
                                        <h3 className="card-title mb-0 ">COMPLETED WORKFLOWS</h3>
                                    </CardHeader>
                                    <CardBody>
                                        <WorkflowHistory workflowData={completedWorkflows} />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </Container>
                </div>)}
        </React.Fragment>
    )
}

export default Workfow

