import { formatDate, formatTime } from "./DateFormatter"

// Utility function to get initials of processor name
export const GetInitials = (name: string) => {
  return name
    ?.split(" ")
    ?.map((word) => word.charAt(0).toUpperCase())
    .join("")
}

// Utility function to format status
export const FormatStatus = (status: string, current_task?: string) => {
  if (status === "in_progress" && current_task === "") {
    status = "scheduled"
  }
  return status
    ?.replace("_", " ")
    ?.replace(/\b\w/g, (char) => char.toUpperCase())
}

export const FormatName = (str: string) => {
  return str
    ?.split("_")
    ?.map((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1))
    ?.join(" ")
}

// Utility function to get and parse session storage data with fallback to default value
export const getSessionStorageData = (key: string, defaultValue = false) => {
  const storedValue = sessionStorage?.getItem(key) || ""
  try {
    if (storedValue) {
      return JSON.parse(storedValue)
    }
  } catch (e) {
    console.error(e)
    // If parsing fails, fallback to the default value
    return defaultValue
  }
}

export const separateWorkflowsByStatus = (response: any) => {
  const completed: any = []
  const inProgress: any = []

  response?.forEach((item: any) => {
    const formattedWorkflow = formatWorkflowForDisplay({
      worklfowId: item?.id,
      item: item,
    })

    if (item?.status === "completed") {
      completed.push(formattedWorkflow)
    } else {
      inProgress.push(formattedWorkflow)
    }
  })

  return { completed, inProgress }
}

export const formatWorkflowForDisplay = ({ worklfowId, item }: any) => {
  const formattedWorkflow = {
    name: FormatName(item?.workflow_display_name), // Convert workflow_name to title case
    date: item?.created_at !== "" ? formatDate(item?.created_at) : "", // Format start_time to MM-DD-YYYY
    start_time: item?.start_time !== "" ? formatTime(item?.start_time) : "", // Format time to AM/PM
    end_time: item?.end_time !== "" ? formatTime(item?.end_time) : "", // Format time to AM/PM
    processor: GetInitials(item?.processor_name), // Get initials from processor_name
    current_Task: item?.current_running_task || "", // Handle missing current_running_task
    status:
      item?.status !== ""
        ? FormatStatus(item?.status, item?.current_running_task)
        : "",
    tasks: item?.tasks, // Format status
    allocatedTime: item?.allocated_time,
    started_time: item?.start_time,
    workflow_id: worklfowId,
  }

  return formattedWorkflow
}

export const separateTicketsByStatus = (response: any) => {
  const completed: any = []
  const active: any = []

  response?.forEach((ticket: any) => {
    const formattedTickets = {
      ticketID: ticket?.ticket_id,
      workflowName: ticket?.workflow_name,
      workflowID: ticket?.workflow_id,
      taskName: ticket?.task_name,
      subject: ticket?.error?.subject,
      createAt:
        ticket?.created_at !== ""
          ? formatDate(ticket?.created_at) +
            " ," +
            formatTime(ticket?.created_at)
          : "",
      status: ticket?.ticket_status,
      priority: ticket?.priority,
      url: ticket?.ticket_url,
    }

    if (ticket?.ticket_status === "open") {
      active.push(formattedTickets)
    } else {
      completed.push(formattedTickets)
    }
  })

  return { active, completed }
}

export const getStatusBadge = (status: string) => {
  const statusStyles: any = {
    completed:
      "badge bg-success-subtle border-2 border-success text-success capitalize",
    failed:
      "badge bg-danger-subtle border-2 border-danger text-danger capitalize",
    ready: "badge bg-info-subtle border-2 border-info text-info capitalize",
    settled: "badge bg-info-subtle border-2 border-info text-info capitalize",
    scheduled: "badge bg-info-subtle border-2 border-info text-info capitalize",
    solved:
      "badge bg-success-subtle border-2 border-success text-success capitalize",
    open: "badge bg-info-subtle border-2 border-info text-info capitalize",
    default:
      "badge bg-warning-subtle border-2 border-warning text-warning capitalize",
  }

  return statusStyles[status] || statusStyles.default
}

export const separateActionsByStatus = ({ workflows, actions }: any) => {
  const completed: any = []
  const pending: any = []
  const failed: any = []

  actions?.forEach((item: any) => {
    if (workflows) {
      const matchingWorkflow = workflows?.find(
        (workflow: any) => workflow?.id === item?.workflow_id
      )

      const formattedWorkflow = {
        id: item?.id,
        actionName: item?.display_name, // Convert workflow_name to title case
        taskName: FormatName(item?.task_name),
        taskId: item?.task_id,
        workflowId: item?.workflow_id,
        workflowName: FormatName(item?.workflow_name),
        createdDate:
          item?.created_at !== ""
            ? formatDate(item?.created_at) + " ," + formatTime(item?.created_at)
            : "",
        completedDate:
          item?.end_time !== ""
            ? formatDate(item?.end_time) + " ," + formatTime(item?.end_time)
            : "",
        timeElapsed: "",
        actionPath: item?.action_path,
        status: item?.status,
        taskKeyName: item?.task_key_name,
        createdAt: item?.created_at,
        key: formatWorkflowForDisplay({
          worklfowId: item?.workflow_id,
          item: matchingWorkflow,
        }),
      }

      if (item?.status === "completed") {
        completed.push(formattedWorkflow)
      } else if (item?.status === "ready") {
        pending.push(formattedWorkflow)
      } else if (item?.status === "failed") {
        failed.push(formattedWorkflow)
      }
    }
  })

  return { completed, pending, failed }
}

export const formatZOSummartReportForDisplay = (item: any) => {
  const formattedReport = {
    productType: FormatName(item?.product_type),
    trusteePortion: item?.trustee_portion,
    trusteePortionStatus: item?.trustee_portion_status,
    feePortion: item?.fee_portion,
    feePortionStatus: item?.fee_portion_status,
    runningZeroOutBal: item?.running_zero_out_balance,
  }

  return formattedReport
}
