import { createSlice } from "@reduxjs/toolkit";

// Initial state
const initialState = {
    workflows: [], // Single global state to hold all workflows
    loading: false,
    error: null,
};

const workflowSlice = createSlice({
    name:"workflow",
    initialState,
    reducers: {
        fetchWorkflowsStart: (state) => {
            state.loading = true; // Set loading to true when the fetch starts
        },
         // Action to update workflows from SSE data
         updateWorkflows: (state, action) => {
            const updatedWorkflows = action.payload; // This should be an array of updated workflow objects
            // Create a map of existing workflows by ID for quick lookup
            const existingWorkflowsMap = new Map(state.workflows.map(workflow => [workflow.id, workflow]));
            updatedWorkflows.forEach((updatedWorkflow) => {
                if (existingWorkflowsMap.has(updatedWorkflow.id)) {
                    // Update the existing workflow
                    existingWorkflowsMap.set(updatedWorkflow.id, {
                        ...existingWorkflowsMap.get(updatedWorkflow.id),
                        ...updatedWorkflow // Merge existing and updated properties
                    });
                }
            });

            // Convert the map back to an array and update the state
            state.workflows = Array.from(existingWorkflowsMap.values())
        },
        fetchWorkflowsSuccess: (state,action) => {
            state.workflows = action.payload; // Store all workflows in the global state
            state.loading = false;
        },
        fetchWorkflowsFailure: (state,action) => {
            state.loading = false;
            state.error = action?.payload;
        }
    },
})


// Export actions and reducer
export const { updateWorkflows,  fetchWorkflowsStart, fetchWorkflowsSuccess, fetchWorkflowsFailure } = workflowSlice.actions;
export default workflowSlice.reducer;
