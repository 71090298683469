import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
// Redux for Tickets
import { fetchTickets } from 'slices/tickets/thunk'
// import reusable components
import BreadCrumb from "Components/Common/BreadCrumb"
import { Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap"
import classnames from 'classnames'
// import page components
import { TicketListing } from "./Listing"
import { separateTicketsByStatus } from "helpers/Formatters/Format"

const Ticket = () => {
    document.title = "Title | TFS Processing App"

    const dispatch = useDispatch()

    const { tickets, loadingTickets } = useSelector((state) => state.Ticket)

    const [activeTab, setActiveTab] = useState(1)
    const [activeTickets, setActiveTickets] = useState([])
    const [completedTickets, setCompletedTickets] = useState([])

    // Fetch Tickets ONLY once on Page load
    useEffect(() => {
        dispatch(fetchTickets())
    }, [])

    // To run when tickets OR loadingTickets is changed
    useEffect(() => {
        if (tickets && !loadingTickets) {
            const { active, completed } = separateTicketsByStatus(tickets)

            setActiveTickets(active)
            setCompletedTickets(completed)
        }
    }, [loadingTickets, tickets])

    // Function to toggle tabs to Active and Completed Tickets 
    const toggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
    }

    return (<React.Fragment>
        {loadingTickets ? (
            <div className="loader-container">
                {/* Loader when data is loading */}
                <div className="loader"></div>
            </div>
        ) :
            (<div className="page-content" id="processingContent">
                <Container fluid>
                    {/* Page Heading */}
                    <BreadCrumb title="Ticket" pageTitle="Ticket" action="/tickets" />
                    <Row className='relative'>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Nav tabs className="nav-tabs-custom nav-success mb-3">
                                        {/* Toggles to Active Tickets */}
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === 1 })}
                                                onClick={() => toggle(1)}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                ACTIVE
                                            </NavLink>
                                        </NavItem>
                                        {/* Toggles to Completed Tickets */}
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === 2 })}
                                                onClick={() => toggle(2)}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                COMPLETED
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <TabContent activeTab={activeTab}>
                                        {/* Active Ticket Listing */}
                                        <TabPane tabId={1}>
                                            <TicketListing tickets={activeTickets} />
                                        </TabPane>
                                        {/* Completed Ticket Listing */}
                                        <TabPane tabId={2}>
                                            <TicketListing tickets={completedTickets} />
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>)}

    </React.Fragment>)
}

export default Ticket