import Button from "Components/Common/Button"

export const TaskEscalationContent = ({ ticketTitle, ticketSubject, setOpenEscalation, handleTicketConfirmation }: any) => {

    return (<div className='px-3 flex flex-col gap-2 bg-stone-100 rounded-xl mx-3 py-3'>
        <h6 className='uppercase font-semibold'>Create Ticket</h6>
        <input type="text" className="border-0 rounded px-3 py-2" placeholder="Title" ref={ticketTitle} />
        <textarea className="border-0 px-3 py-2" rows={5} placeholder="Subject" ref={ticketSubject}></textarea>
        <div className='flex justify-end gap-2 mt-2'>
            <Button onClick={() => setOpenEscalation({ open: false })} label="Cancel" styleType="secondary" />
            <Button onClick={handleTicketConfirmation} label="Send" styleType="confirm" />
        </div>
    </div>)
}