import React, { useMemo } from 'react'
import TableContainer from "../../Components/Common/TableContainerReactTable"
import { useNavigate } from 'react-router-dom'
import { GET_WORKFLOWS } from 'slices/constants/constants'
import { determineBaseUrlAndPerformApiCall } from 'slices/interceptor/interceptor'
import { formatWorkflowForDisplay, getStatusBadge } from "../../helpers/Formatters/Format"

export const TicketListing = ({ tickets }) => {
    const navigate = useNavigate()

    const columns = useMemo(
        () => [
            {
                header: "Ticket ID",
                accessorKey: "ticketID",
                enableColumnFilter: false,
                cell: (cell) => {
                    const { url } = cell.row.original // This is the URL you want to redirect to

                    return (
                        <span
                            className="text-blue-500 hover:underline cursor-pointer"
                            onClick={() => {
                                if (url) {
                                    window.open(url, '_blank') // Open in new tab
                                } else {
                                    console.error('URL not found for this ticket')
                                }
                            }}
                        >
                            {cell.getValue()} {/* Render the ticket ID */}
                        </span>
                    )
                },
            },
            {
                header: "Workflow Name",
                accessorKey: "workflowName",
                enableColumnFilter: false,
                cell: (cell) => {
                    // Get the full row data using cell.row.original
                    const { workflowID } = cell.row.original

                    return (
                        <span
                            className="text-blue-500 hover:underline cursor-pointer"
                            onClick={() => {
                                if (workflowID) {
                                    determineBaseUrlAndPerformApiCall(`${GET_WORKFLOWS}/${workflowID}`, 'GET')
                                        .then((response) => {
                                            const dataToPass = formatWorkflowForDisplay({ worklfowId: workflowID, item: response })

                                            if (dataToPass) {
                                                navigate('/inbound-payment-processing', { state: { key: dataToPass } })
                                            }
                                        })
                                }
                            }}
                        >
                            {cell.getValue()} {/* Render the workflow name */}
                        </span>
                    )
                },
            },
            {
                header: "Task Name",
                accessorKey: "taskName",
                enableColumnFilter: false,
            },
            {
                header: "Subject",
                accessorKey: "subject",
                enableColumnFilter: false,
            },
            {
                header: "Created Date",
                accessorKey: "createAt",
                enableColumnFilter: false,
            },
            {
                header: "Priority",
                accessorKey: "priority",
                enableColumnFilter: false,
                cell: (cell) => {
                    switch (cell?.getValue()?.toLowerCase()) {
                        case "urgent":
                            return (<span className="text-danger font-semibold capitalize"> {cell.getValue()}</span>)
                        default:
                            return (<span className="text-info font-semibold capitalize"> {cell.getValue()}</span>)
                    }
                },
            },
            {
                header: "Status",
                enableColumnFilter: false,
                accessorKey: "status",
                cell: (cell) => {
                    const status = cell.getValue().toLowerCase()
                    return (
                        <span className={getStatusBadge(status)}>
                            {cell.getValue()}
                        </span>)
                },
            },
        ],
        []
    )

    return (
        <React.Fragment >
            <TableContainer
                columns={(columns || [])}
                data={(tickets || [])}
                customPageSize={7}
                SearchPlaceholder='Search Products...'
                showPagination={true}
            />
        </React.Fragment >
    )
}