import React, { useMemo } from "react"
// import utilities
import { FormatName, getStatusBadge } from "helpers/Formatters/Format"
import TableContainer from "Components/Common/TableContainerReactTable"
import { formatDate } from "helpers/Formatters/DateFormatter"

export const TransactionList = ({
  transactionList,
  pageLimit,
  currentPageNumber, 
  setCurrentPageNumber
}: any) => {
  // Table headings
  const columns = useMemo(
    () => [
      {
        header: "Transaction ID",
        accessorKey: "transaction_id",
        enableColumnFilter: false,
      },
      {
        header: "Type",
        accessorKey: "type",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return <span>{FormatName(cell.getValue())}</span>
        },
      },
      {
        header: "Status",
        enableColumnFilter: false,
        accessorKey: "status",
        cell: (cell: any) => {
          const status = cell.getValue().toLowerCase()
          return (
            <span className={getStatusBadge(status)}>{cell.getValue()}</span>
          )
        },
      },
      {
        header: "Created Date",
        accessorKey: "created_date",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return <span>{formatDate(cell.getValue())}</span>
        },
      },
      {
        header: "Updated Date",
        accessorKey: "updated_date",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return <span>{formatDate(cell.getValue())}</span>
        },
      },
      {
        header: "Debtor Name",
        accessorKey: "debtor_name",
        enableColumnFilter: false,
      },
      {
        header: "Trustee Name",
        accessorKey: "trustee_name",
        enableColumnFilter: false,
      },
      {
        header: "Bundle ID",
        accessorKey: "bundle_id",
        enableColumnFilter: false,
      },
      {
        header: "Total Amount",
        accessorKey: "total_amount",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return <span className="text-secondary">{cell.getValue()}</span>
        },
      },
      {
        header: "Fee Amount",
        accessorKey: "fee_amount",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return <span className="text-secondary">{cell.getValue()}</span>
        },
      },
    ],
    []
  )

  // Renders the Table component
  return (
    <React.Fragment>
      <TableContainer
        columns={columns || []}
        data={transactionList || []}
        customPageSize={10}
        SearchPlaceholder="Search..."
        showPagination={true}
        clickable={true}
        navigateTo='/timeline'      
        pageLimit={pageLimit}
        currentPageNumber={currentPageNumber}
        setCurrentPageNumber={setCurrentPageNumber}
      />
    </React.Fragment>
  )
}
