// Endpoints
export const GET_WORKFLOWS="/workflow/workflow"
export const GET_WORKLFOW_SSE="/workflow/status"
export const GET_WORKFLOW_ACTION_SSE="workflow/actions/stream"
export const GET_WORKFLOW_STATISTICS="/workflow/getStatistics?workflowid="
export const GET_WORKFLOW_ACTIONS="/workflow/getActions"
export const GET_TICKET_SSE="/tickets/status"
export const GET_TICKET_LIST="/tickets/getTickets"
export const GET_NOTIFICATION_UPDATEREADBY="/notification/updateReadby"
export const CAMUNDA_INBOUND="/inbound"
export const GET_NOTIFICATION_PER_USER="/notification/userNotifications?user"
export const ZERO_OUT_SUMMARY_REPORT="/zero-out/reports?date="
export const ZERO_OUT_INBOUND_REPORT="/zero-out/reports/inbound-day-summary?"
export const ZERO_OUT_INBOUND_REPORT_PAGE_SIZE=10
export const ZERO_OUT_TIMELINE="/zero-out/timeline"
export const SSE_PATH="/workflow/sse_updates?user="

// Task Key Names
export const TASK_CREATE_BUNDLE = "create_bundle"
export const TASK_APPROVE_CREATE_BUNDLE = "approve_create_bundle"
export const TASK_UPLOAD_PAYMENT_BUNDLE = "upload_payment_bundle"
export const TASK_UPLOAD_BUNDLE = "upload_bundle"
export const TASK_RESTART_CREATE_BUNDLE = "restart_create_bundle"
export const TASK_RESTART_UPLOAD_PAYMENT_BUNDLE = "restart_upload_payment_bundle"

// Workflow States
export const STATUS_READY="ready"
export const STATUS_IN_PROGRESS="in_progress"
export const STATUS_COMPLETED="completed"
export const STATUS_FAILED="failed"
export const STATUS_SCHEDULED="scheduled"
export const STATUS_PENDING="pending"
export const STATUS_POSTED="posted"

// Ticket States
export const TICKET_SOLVED = "solved"
export const TICKET_OPEN = "open"

// Transaction Types
export const INBOUND_ACH="inbound_ach" // Request change
export const MONEY_GRAM="money_gram" // Request change
export const WAGE_DEDUCTION="wage_deduction" // Request change
export const DEBIT_CARD="debit_card" // Request change