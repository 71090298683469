import React from 'react'

interface ButtonProps {
    onClick: () => void
    label: string
    styleType?: 'primary' | 'secondary' | 'confirm' // Use types for styling variations
    disabled?: boolean
}

const Button: React.FC<ButtonProps> = ({
    onClick,
    label,
    styleType = 'secondary',
    disabled = false
  }) => {
    const baseStyles = 'text-xs font-bold py-1 px-3 rounded-md transition-all duration-200'
    const styleClasses = {
      primary: 'bg-white shadow-gray-300 shadow-inner-all-sides border-2 border-[#DBDBDB] active:bg-gray-300 active:shadow-none',
      secondary: 'bg-white shadow-gray-300 shadow-inner-all-sides border-2 border-[#DBDBDB] active:bg-gray-400 active:shadow-none',
      confirm: 'bg-[#31506A] shadow-inner-all-sides text-white border-[0.09px] border-[#51718b] active:bg-[#31506A] active:shadow-none',
    }
  
    return (
        <button
            className={`${baseStyles} ${styleClasses[styleType]}`}
            onClick={onClick}
            disabled={disabled}
        >
            {label}
        </button>
    )
}

export default Button
