import { createSlice } from "@reduxjs/toolkit";

// Initial state
const initialState = {
    actions: [], // Single global state to hold all actions
    loadingActions: false,
    error: null,
};

const actionSlice = createSlice({
    name: 'action',
    initialState,
    reducers: {
        fetchActionsStart: (state) => {
            state.loadingActions = true; // Set loading to true when the fetch starts
        },
        // Action to update actions from SSE data
        updateActions: (state, action) => {
            const updatedActions = action.payload; // This should be an array of updated action objects
            // Iterate over the updated actions
            updatedActions.forEach((updatedAction) => {

                const existingActionIndex = state.actions.findIndex(ticket => ticket.id === updatedAction.id);

                if (existingActionIndex !== -1) {
                    // Update the existing action in the array

                    state.actions[existingActionIndex] = {
                        ...state.actions[existingActionIndex], // Merge existing action properties
                        ...updatedAction // Merge updated properties
                    };
                } else {
                    state.actions.push(updatedAction);
                    state.actions.sort((a, b) => b.id - a.id);
                }
            });
        },
        fetchActionsSuccess: (state, action) => {
            state.actions = action.payload; // Store all actions in the global state
            state.loadingActions = false;
        },
        fetchActionsFailure: (state, action) => {
            state.loadingActions = false;
            state.error = action?.payload;
        }
    }
});

// Export actions and reducer
export const { updateActions, fetchActionsSuccess, fetchActionsFailure, fetchActionsStart } = actionSlice.actions;
export default actionSlice.reducer;
