import React, { Fragment, useEffect, useState } from "react";
import { CardBody, Col, Row, Table } from "reactstrap";

import {
  Column,
  Table as ReactTable,
  ColumnFiltersState,
  FilterFn,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table";

import { rankItem } from "@tanstack/match-sorter-utils";
import { STATUS_PENDING } from "slices/constants/constants";
import { useNavigate } from "react-router-dom";

// Column Filter
const Filter = ({
  column,
}: {
  column: Column<any, unknown>;
  table: ReactTable<any>;
}) => {
  const columnFilterValue = column.getFilterValue();

  return (
    <>
      <DebouncedInput
        type="text"
        value={(columnFilterValue ?? "") as string}
        onChange={(value) => column.setFilterValue(value)}
        placeholder="Search..."
        className="w-36 border shadow rounded"
        list={column.id + "list"}
      />
      <div className="h-1" />
    </>
  );
};

// Global Filter
const DebouncedInput = ({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [debounce, onChange, value]);

  return (
    <input
      {...props}
      value={value}
      id="search-bar-0"
      className="form-control border-0 search"
      onChange={(e) => setValue(e.target.value)}
    />
  );
};

interface TableContainerProps {
  columns?: any;
  data?: any;
  isGlobalFilter?: any;
  handleTaskClick?: any;
  customPageSize?: any;
  tableClass?: any;
  theadClass?: any;
  trClass?: any;
  iscustomPageSize?: any;
  thClass?: any;
  divClass?: any;
  SearchPlaceholder?: any;
  handleLeadClick?: any;
  handleCompanyClick?: any;
  handleContactClick?: any;
  handleTicketClick?: any;
  isBordered?: any;
  showPagination?: any;
  clickable?: boolean;
  navigateTo?: any;
  isHistoryNavigate?: boolean;
  fboStartingBalance: any;
  selectedDate?:any;
}

const TableContainer = ({
  columns,
  data,
  isGlobalFilter,
  customPageSize,
  theadClass,
  trClass,
  thClass,
  divClass,
  SearchPlaceholder,
  clickable,
  navigateTo,
  fboStartingBalance,
  selectedDate
}: TableContainerProps) => {
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const history = useNavigate()

  const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value);
    addMeta({
      itemRank,
    });
    return itemRank.passed;
  };

  const table = useReactTable({
    columns,
    data,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const { getHeaderGroups, setPageSize } = table;

  useEffect(() => {
    if (Number(customPageSize)) {
      setPageSize(Number(customPageSize));
    }
  }, [customPageSize, setPageSize]);

  return (
    <Fragment>
      {isGlobalFilter && (
        <Row className="mb-3">
          <CardBody className="border border-dashed border-end-0 border-start-0">
            <form>
              <Row>
                <Col sm={5}>
                  <div className="search-box me-2 mb-2 d-inline-block col-12">
                    <DebouncedInput
                      value={globalFilter ?? ""}
                      onChange={(value) => setGlobalFilter(String(value))}
                      placeholder={SearchPlaceholder}
                    />
                    <i className="bx bx-search-alt search-icon"></i>
                  </div>
                </Col>
              </Row>
            </form>
          </CardBody>
        </Row>
      )}

      <div className="table-responsive">
        <div className={divClass}>
          <Table className={"table-nowrap align-middle mb-0"}>
            <thead className={theadClass}>
              <tr>
                {/* TODO */}
                <th colSpan={5}>
                  <div className="flex justify-between flex-row items-center">
                    <p className="p-0 m-0 text-sm uppercase text-gray-700">
                      Active Window (DAYS) - 10
                    </p>
                    <div className="flex gap-2">
                      <p className="text-sm text-gray-700 p-0 m-0">
                        FBO Starting Balance:
                      </p>
                      <p className="text-blue-600 font-bold m-0 p-0">
                        $ {fboStartingBalance}
                      </p>
                    </div>
                  </div>
                </th>
              </tr>
              {getHeaderGroups().map((headerGroup: any) => (
                <tr className={trClass} key={headerGroup.id}>
                  {headerGroup.headers.map((header: any) => (
                    <th
                      key={header.id}
                      className={`${thClass} w-1/5`}
                      {...(clickable === true && {
                        onClick: header.column.getToggleSortingHandler(),
                      })}
                    >
                      {header.isPlaceholder ? null : (
                        <React.Fragment>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: " ",
                            desc: " ",
                          }[header.column.getIsSorted() as string] ?? null}
                          {header.column.getCanFilter() ? (
                            <div>
                              <Filter column={header.column} table={table} />
                            </div>
                          ) : null}
                        </React.Fragment>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              <tr className="bg-[#f2f2f7]">
                <td colSpan={5} className="p-0 m-0">
                  <div className="flex flex-col gap-3 w-full h-[43rem] overflow-auto">
                    {data?.map((item: any, index: number) => (
                      <div
                        key={index}
                        className={`cursor-pointer w-full grid grid-cols-5 bg-white border-1
                           border-gray-300 rounded-md
                           hover:border-gray-500`}
                        onClick={() => {
                          const key ={
                            inboundDate: item?.inboundDate, asOfEndOfDay: selectedDate
                          }
                          history(navigateTo, { state: key });
                        }}
                      >
                        <div className="row-span-4">
                          <div className="flex justify-center items-center w-full h-full">
                            <div className="flex flex-col text-gray-700 w-full text-left ml-8">
                              <h2 className="m-0 p-0">{item?.index}</h2>
                              <h5 className="font-bold text-xs lg:text-base">
                                {item?.inboundDate}
                              </h5>
                            </div>
                          </div>
                        </div>
                        <div className="col-span-4">
                          {item?.data?.map(
                            (result: any, resultIndex: number) => (
                              <div
                                key={resultIndex}
                                className={`grid grid-cols-4 w-full ${
                                  resultIndex % 2 === 0 ? "bg-[#F4F3F2]" : ""
                                }`}
                              >
                                <div className="ml-3 pt-1">
                                  <p>{result?.productType || "-"}</p>
                                </div>
                                <div className="text-right mr-3">
                                  <p
                                    className={`mt-1 ${
                                      result?.trusteePortionStatus ===
                                      STATUS_PENDING
                                        ? "text-red-600"
                                        : "text-secondary"
                                    }`}
                                  >
                                    $ {result?.trusteePortion || "-"}
                                  </p>
                                </div>
                                <div className="text-right mr-3 ">
                                  <p
                                    className={`mt-1 ${
                                      result?.feePortionStatus ===
                                      STATUS_PENDING
                                        ? "text-red-600"
                                        : "text-secondary"
                                    }`}
                                  >
                                    $ {result?.feePortion || "-"}
                                  </p>
                                </div>
                                <div className="text-secondary text-right pr-4 mr-3">
                                  <p className="mt-1">
                                    $ {result?.runningZeroOutBal || "-"}
                                  </p>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </Fragment>
  );
};

export default TableContainer;
