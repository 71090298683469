import { FormatStatus } from "helpers/Formatters/Format"

export const renderStatusBadge = (status: string) => {
    const statusMap: Record<string, string> = {
        ready: "info",
        completed: "success",
        in_progress: "warning",
        pending: "warning",
        failed: "danger",
        escalated: "info"
    }

    const color = statusMap[status] || "info"
    return (
        <span className={`badge bg-${color}-subtle border-2 border-${color} text-${color} capitalize`}>
            {FormatStatus(status)}
        </span>
    )
}