/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect, useRef } from "react"
import { Card, Col, Container, Row } from "reactstrap"
import BreadCrumb from "../../../Components/Common/BreadCrumb"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import { DateTime } from "ts-luxon"
import { determineBaseUrlAndPerformApiCall } from "slices/interceptor/interceptor"
import { ZERO_OUT_SUMMARY_REPORT } from "slices/constants/constants"
import { formatZOSummartReportForDisplay } from "helpers/Formatters/Format"
import { ZOSummaryListing } from "./Listing"
import { toast, ToastContainer } from "react-toastify"
import { formatDate } from "helpers/Formatters/DateFormatter"

const ZeroOutSummaryReport = () => {
  document.title = "Zero Out Summary | TFS Processing App"

  const [zOSummary, setZOSummary] = useState<any>([])
  const [loading, setLoading] = useState(true)
  const [selectedDate, setSelectedDate] = useState<Date | null>(null)
  const datePickerRef = useRef<any>(null)
  const todayFormatted = DateTime.now()
    .setZone("America/New_York")
    .toFormat("MM-dd-yyyy")
  const [details, setDetails] = useState<any>({})

  useEffect(() => {
    // Get today's date in ET
    const todayET = DateTime.now().setZone("America/New_York")
    const formattedDate = todayET.toFormat("yyyy-MM-dd")

    if (formattedDate) {
      setSelectedDate(todayET.toJSDate())
    }
  }, [])

  const handlePreviousDate = () => {
    setSelectedDate((prevDate) => {
      const date = prevDate ? new Date(prevDate) : new Date()
      date.setDate(date.getDate() - 1)
      return date
    })
  }

  const handleNextDate = () => {
    setSelectedDate((prevDate) => {
      const date = prevDate ? new Date(prevDate) : new Date()
      date.setDate(date.getDate() + 1)
      return date
    })
  }

  const handleIconClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true)
    }
  }

  useEffect(() => {
    if (selectedDate) {
      setLoading(true)
      const year = selectedDate.getFullYear()
      const month = (selectedDate.getMonth() + 1).toString().padStart(2, "0") // Months are zero-indexed
      const day = selectedDate.getDate().toString().padStart(2, "0")
      const formattedSelectedDate = `${year}-${month}-${day}`

      const getSummary = async () => {
        try {
          await determineBaseUrlAndPerformApiCall(
            `${ZERO_OUT_SUMMARY_REPORT}${formattedSelectedDate}`,
            "GET"
          )
            .then((response: any) => {
              // Bascially loop through transactions, and form an array
              // Set that to the summary data
              setDetails({
                fboStartingBalance: response?.fbo_starting_balance,
                esquireFeeCollected: response?.esquire_fee_collected,
                zeroOutBalance: response?.zero_out_balance,
              })

              const item = response?.transactions?.map(
                (item: any, index: any) => {
                  const formmatedReportData = item?.data?.map((data: any) => {
                    return formatZOSummartReportForDisplay(data)
                  })

                  const report = {
                    inboundDate: formatDate(item?.inbound_date),
                    index: index + 1,
                    data: formmatedReportData,
                  }
                  return report
                }
              )
              setZOSummary(item)
              setLoading(false)
            })
            .catch((errorResp) => {
              toast.error(errorResp, { position: "top-center" })
              console.error(errorResp)
            })
        } catch (error: any) {
          toast.error(error?.message, { position: "top-center" })
          console.error(error)
        }
      }
      getSummary()
    }
  }, [selectedDate])

  return (
    <React.Fragment>
      {/* Display loader if the reports are yet to be fetched from the API */}
      {loading ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : (
        <div className="page-content" id="processingContent">
          <ToastContainer />
          <Container fluid>
            {/* Page Heading */}
            <BreadCrumb
              title="Zero Out Summary Report"
              pageTitle="Report"
              action="/zero-out-summary-report"
            />

            {/* Date Picker */}
            <div className="flex flex-col items-center justify-center gap-2">
              <p className="text-gray-700 font-semibold m-0 p-0">
                As of End of Day
              </p>
              <div className="flex items-center gap-x-4">
                {/* Left Arrow */}
                <div
                  onClick={handlePreviousDate}
                  className="rounded bg-[#31506A] cursor-pointer hover:bg-[#2b435a] px-2"
                >
                  <i className="ri-arrow-left-line text-white text-lg" />
                </div>
                {/* Date Picker */}
                <div className="relative inline-flex items-center w-auto justify-centre">
                  <DatePicker
                    className="border-1 border-black-500 rounded-md font-bold placeholder:text-black  h-8 pl-4 text-sm w-36"
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    dateFormat="MM-dd-yyyy"
                    placeholderText={todayFormatted}
                    ref={datePickerRef}
                  />
                  <i 
                  className="ri-calendar-2-line absolute right-5 bottom-1 text-gray-500 text-lg cursor-pointer"
                  onClick={handleIconClick}
                  />
                </div>
                {/* Right Arrow */}
                <div
                  onClick={handleNextDate}
                  className="rounded bg-[#31506A] cursor-pointer hover:bg-[#2b435a] px-2"
                >
                  <i className="ri-arrow-right-line text-white text-lg" />
                </div>
              </div>
            </div>

            {/* Summary Report */}
            <Row>
              <Col>
                <Card className="mt-4">
                  <ZOSummaryListing selectedDate={selectedDate} summaryData={zOSummary} fboStartingBalance={details?.fboStartingBalance}/>
                </Card>
              </Col>
            </Row>

            <div className="flex flex-col mb-4 gap-2">
              <div className="flex justify-end w-full">
                <div className="bg-white rounded flex gap-9 justify-center px-4 py-2 border border-gray-300">
                  <p className="font-bold m-0 p-0">Esquire Fees Collected But Not Sent, Not Including Full Fee Totals Collected Above</p>
                  <p className="text-blue-500 font-bold m-0 p-0">
                    $ {details?.esquireFeeCollected}
                  </p>
                </div>
              </div>
              <div className="flex justify-end w-full">
                <div className="bg-white rounded flex gap-9 justify-center px-4 py-2 border border-gray-300">
                  <p className="font-bold m-0 p-0"> Zero Out Balance</p>
                  <p className="text-blue-500 font-bold m-0 p-0">
                    $ {details?.zeroOutBalance}
                  </p>
                </div>
              </div>
            </div>
          </Container>
        </div>
      )}
    </React.Fragment>
  )
}

export default ZeroOutSummaryReport
