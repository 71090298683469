import Button from "Components/Common/Button";
import { GetStatiscs } from "pages/Workflow/InboundPaymentProcessing/Statistics";
import { Card, CardBody, CardHeader } from "reactstrap";

export const renderTaskStatistics: any = (tasks: any, stats: any) => {
  if (
    tasks &&
    tasks?.some(
      (task: any) =>
        task?.key_name === "create_bundle" && task?.status === "completed"
    )
  ) {
    return (
      <Card className="border-1 mt-4">
        <CardHeader>
          <h5 className="card-title mb-0">Statistics</h5>
        </CardHeader>
        <CardBody className="text-xs">
          <GetStatiscs statistics={stats} />
        </CardBody>
      </Card>
    );
  }
};

export const TaskActionContent = ({
  openTab,
  workflowId,
  stats,
  handleEscalate,
  setOpenTab,
  handleConfirm,
  tasks,
}: any) => {
  return (
    <div className="px-2 flex flex-col h-5/6 justify-between">
      <div className="flex-col gap-4">
        <div className="flex-col gap-2 px-3">
          <div className="flex w-3/4 pb-2">
            <span className="font-semibold  w-1/2">Task Name: </span>
            <span>{openTab?.actionName}</span>
          </div>
          <div className="flex w-3/4 pb-2">
            <span className="font-semibold w-1/2">Task ID: </span>
            <span>{openTab?.taskId}</span>
          </div>
          <div className="flex w-3/4">
            <span className="font-semibold w-1/2">Workflow ID: </span>
            <span>{workflowId}</span>
          </div>
        </div>
        {renderTaskStatistics(tasks, stats)}
      </div>
      <div className="flex justify-end gap-2 mr-4">
        {openTab.actionKeyName === "approve_create_bundle" ||
        openTab.actionKeyName === "upload_bundle" ? (
          <Button
            onClick={handleEscalate}
            label="Escalate"
            styleType="primary"
          />
        ) : (
          <Button
            onClick={() => setOpenTab({ open: false })}
            label="Cancel"
            styleType="secondary"
          />
        )}
        <Button onClick={handleConfirm} label={openTab?.label} styleType="confirm" />
      </div>
    </div>
  );
};
