import React, { useMemo } from 'react';
import TableContainer from "../../Components/Common/TableContainerReactTable";
import { useNavigate } from 'react-router-dom';
import loadingIcon from '../../assets/images/loading.png'
import { getStatusBadge } from 'helpers/Formatters/Format';

export const ActiveActionList = ({ setOpenTab, actionsData }) => {
    const navigate = useNavigate();

    const columns = useMemo(
        () => [
            {
                header: "Action",
                accessorKey: "actionName",
                enableColumnFilter: false,
            },
            {
                header: "Task Name",
                accessorKey: "taskName",
                enableColumnFilter: false,
            },
            {
                header: "Workflow",
                accessorKey: "workflowName",
                enableColumnFilter: false,
                cell: (cell) => {
                    // Get the full row data using cell.row.original
                    const row = cell.row.original;
                    const key = row?.key;  // This is the URL you want to redirect to
                    return (
                        <span
                            className="text-blue-500 hover:underline cursor-pointer"
                            onClick={() => {
                                if (key) {
                                    navigate('/inbound-payment-processing', { state: { key: key } });
                                }
                            }}
                        >
                            {cell.getValue()} {/* Render the workflow name */}
                        </span>
                    );
                },
            },
            {
                header: "Created Date",
                accessorKey: "createdDate",
                enableColumnFilter: false,
            },
            {
                header: "Time Elapsed",
                accessorKey: "timeElapsed",
                enableColumnFilter: false,
                cell: (cell) => {
                    // Get the full row data using cell.row.original
                    const { timeElapsed } = cell.row.original;
                    return (timeElapsed ?
                        (<span
                        >
                            {cell.getValue()} {/* Render the workflow name */}
                        </span>) : (<div className="w-full h-full">
                            <img src={loadingIcon} className=" animate-spin h-4" alt="loading-icon"/>
                        </div>)
                    );
                },
            },
            {
                header: "Status",
                enableColumnFilter: false,
                accessorKey: "status",
                cell: (cell) => {
                    const status = cell.getValue().toLowerCase()
                    return (
                        <span className={getStatusBadge(status)}>
                            {cell.getValue()}
                        </span>
                    )
                },
            },
            {
                header: "",
                accessorKey: " ",
                enableColumnFilter: false,
                // Conditionally render the cell only if the status is not "completed"
                cell: (cell) => {
                    const { status } = cell.row.original;
                    return <div className="d-flex justify-content-between">
                        {cell.getValue() || ''}
                        <i
                            className="ri-skip-forward-mini-line"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                if (status === "ready") {
                                    setOpenTab({
                                        id: cell.row.original.id,
                                        taskId: cell.row.original.taskId,
                                        taskName: cell.row.original.taskName,
                                        taskKeyName: cell.row.original.taskKeyName,
                                        actionPath: cell.row.original.actionPath,
                                        open: true,
                                        label: cell.row.original.taskKeyName ==="approve_create_bundle" || 
                                        cell.row.original.taskKeyName ==="upload_bundle"?
                                        "Approve":"Start",
                                        actionName: cell.row.original.actionName,
                                        workflowId: cell.row.original.workflowId
                                    });
                                }
                            }}
                        ></i>
                    </div>

                },
            },
        ],
        []
    );

    return (
        <React.Fragment>
            <TableContainer
                columns={columns}
                data={actionsData}
                customPageSize={20}
                SearchPlaceholder="Search..."
                showPagination={false}
            // clickable
            />
        </React.Fragment>
    );
};
