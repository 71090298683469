import { combineReducers } from "redux"

// Front
import LayoutReducer from "./layouts/reducer"

// Authentication
import LoginReducer from "./auth/login/reducer"

// Import the workflow reducer
import workflowReducer from "./workflow/reducer"

// Import the Ticket Reducer
import ticketReducer from "./tickets/reducer"

// Import the Action Reducer
import ActionReducer from "./Actions/reducer"

// Import the Statistics Reducer
import StatisticsReducer from "./workflow/statistics/reducer"

// Import the Notification Reducer
import NotificationsReducer from "./Notification/reducer"

const rootReducer = combineReducers({
    Layout: LayoutReducer,
    Login: LoginReducer,
    Workflow: workflowReducer,
    Statistics: StatisticsReducer,
    Ticket: ticketReducer,
    Action: ActionReducer,
    Notifications: NotificationsReducer
})

export default rootReducer