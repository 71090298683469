import React from "react"
// import common component
import Timer from "Components/Common/Timer"
// import page component
import { TaskData } from "./Task"

export const TaskCardContent = ({
  startTime,
  allocatedTime,
  status,
  tasks,
  openTab,
  setOpenTab,
}) => {
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full border-none">
        <thead>
          <tr>
            <th
              colSpan="6"
              className="px-4 py-2 font-semibold text-left border-b border-b-gray-100"
            >
              TASKS
            </th>
            {/* Renders the Workflow Timer  */}
            <th rowSpan="2">
              <Timer
                startedTime={startTime}
                allocatedTime={allocatedTime}
                status={status}
              />
            </th>
          </tr>
          {/* Renders the Task Headings */}
          <tr>
            <th className="px-8 py-2 font-semibold">Task Name</th>
            <th className="px-4 py-2  font-semibold">Task ID</th>
            <th className="px-4 py-2 font-semibold">Start Time</th>
            <th className="px-4 py-2  font-semibold">End Time</th>
            <th className="px-4 py-2 font-semibold">Time Left</th>
            <th className="px-4 py-2 font-semibold">Status</th>
          </tr>
        </thead>
        {/* Renders the Task Details Body */}
        <tbody>
          {tasks?.map((task) => (
            <TaskData
              key={task?.id}
              taskData={task}
              workflowStatus={status}
              setOpenTab={setOpenTab}
              openTab={openTab}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}
