import { formatWorkflowForDisplay } from "helpers/Formatters/Format"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { TASK_APPROVE_CREATE_BUNDLE, TASK_CREATE_BUNDLE, TASK_UPLOAD_BUNDLE, TASK_UPLOAD_PAYMENT_BUNDLE } from "slices/constants/constants"
import { determineBaseUrlAndPerformApiCall } from "slices/interceptor/interceptor"

export const getWorkflowById = (workflows: any, targetId: any) => workflows?.find((workflow: any) => workflow?.id === targetId)

export const handleStartButtonClick = async (
    {
        taskId,
        actionPath,
        actionKeyName,
        setOpenTab,
        toNavigate,
        workflows,
        workflowId
    }: {
        taskId: string
        actionPath: string
        actionKeyName: string
        setOpenTab: any // Optional 
        toNavigate?: boolean // Optional 
        workflows?: any,  // Optional 
        workflowId?: string  // Optional 
    },
    navigate: ReturnType<typeof useNavigate> // Pass navigate as a parameter
) => {
    // Close the action slider
    setOpenTab({ open: false })

    // Save the disabled state to localStorage
    sessionStorage.setItem(`started_${taskId}`, JSON.stringify(true))

    // Construct the req body
    const body = JSON.stringify({
        [actionKeyName === TASK_CREATE_BUNDLE ? "create_bundle_task_id"
            : actionKeyName === TASK_APPROVE_CREATE_BUNDLE ? "approve_create_bundle_task_id"
                : actionKeyName === TASK_UPLOAD_PAYMENT_BUNDLE ? "upload_bundle_task_id" : "approve_upload_bundle_task_id"]: taskId
    })

    await determineBaseUrlAndPerformApiCall(`${actionPath}`, 'POST', body).then((response: any) => {
        if (!response?.error) {
            if (toNavigate) {

                if (workflows) {
                    const item = workflows?.find((workflow: any) => workflow?.id === workflowId)

                    if (item) {

                        const workflowData = formatWorkflowForDisplay({ worklfowId: workflowId,item:item })
                        const dataToPass = {
                            key: workflowData
                        }

                        if (dataToPass) {
                            // Navigate to the target component and pass data using state
                            navigate('/inbound-payment-processing', { state: dataToPass })
                        }
                    }
                }
            }

            const successMessages: Record<string, string> = {
                [TASK_CREATE_BUNDLE]: 'Workflow Started Successfully!',
                [TASK_APPROVE_CREATE_BUNDLE]: 'Bundle Approved Successfully!',
                [TASK_UPLOAD_PAYMENT_BUNDLE]: 'Upload Payment Bundle Started Successfully!',
                [TASK_UPLOAD_BUNDLE]: 'Payment Bundle Approved Successfully!',
                default: 'Request Sent Successfully!'
            };

            // Show success toast with the appropriate message
            const message = successMessages[actionKeyName] || successMessages.default;
            toast.success(message, { position: 'top-center' });


        }
    }).catch((errorResp) => {
        toast.error(errorResp, {
            position: "top-center"
        })
    })

}

export const handleTicketEsclations = async (
    {
        taskId,
        actionKeyName,
        setOpenTab,
        ticketTitle,
        ticketSubject,
        workflowId,
        workflows,
        setOpenEscalation
    }: {
        taskId: string
        actionPath: string
        actionKeyName: string
        ticketTitle: any
        ticketSubject: any
        setOpenTab: any // Optional 
        setOpenEscalation: any
        workflows: any,  // Optional 
        workflowId: string  // Optional 
    }
) => {
    if (ticketTitle?.current?.value === "") {
        toast.error("Title of the Ticket missing!", {
            position: "top-center"
        })
        return
    }

    if (ticketSubject?.current?.value === "") {
        toast.error("Subject of the Ticket missing !", {
            position: "top-center"
        })
        return
    }

    setOpenTab({ open: false })
    setOpenEscalation({ open: false })

    sessionStorage.setItem(`escalated_${taskId}`, JSON.stringify(true))

    if (workflows) {
        // Loop the workflow and find matching workflow id
        const matchingWorkflows = getWorkflowById(workflows, workflowId)

        const taskNameToLookFor = actionKeyName === TASK_APPROVE_CREATE_BUNDLE ? "create_bundle" : "upload_payment_bundle"

        const task = matchingWorkflows?.tasks?.find((task: any) => task?.key_name === taskNameToLookFor)

        const matchingTask = matchingWorkflows?.tasks?.find((task: any) => task?.key_name === actionKeyName)

        const taskIdKey =
            actionKeyName === TASK_APPROVE_CREATE_BUNDLE ? "create_bundle_task_id" :
                actionKeyName === TASK_UPLOAD_BUNDLE ? "upload_bundle_task_id" : ""

        const data = {
            [taskIdKey]: task?.id, // Ensure valid key 
            ticket_title: ticketTitle?.current?.value,
            ticket_subject: ticketSubject?.current?.value,
        }

        await determineBaseUrlAndPerformApiCall(`${matchingTask?.escalation_path}`, 'POST', data).then((response: any) => {
            if (!response?.error) {
                toast.success("Ticket raised successfully !", {
                    position: "top-center"
                })
                // Reset the input fields
                ticketTitle.current.value = ""
                ticketSubject.current.value = ""
            }
            if (response.error) {
                toast.error(response.error.message, {
                    position: "top-center"
                })
            }
        }).catch((errorResp) => {
            toast.error(errorResp, {
                position: "top-center"
            })
        })

    }
}