import React, { useEffect } from "react"
import { Navigate } from "react-router-dom"
import { useDispatch } from "react-redux"

import { logoutUser } from "../slices/auth/login/thunk"

const AuthProtected = (props: any) => {
  const dispatch: any = useDispatch()
  // const { token } = useProfile()

  const token = sessionStorage.getItem("authToken")
  
  useEffect(() => {
  if (!token) {
      dispatch(logoutUser())
    }
  }, [token, dispatch])

  // Navigate is un-auth access protected routes via url
  if (!token) {
    return (
      <React.Fragment>
        <Navigate to={{ pathname: "/login" }} />
      </React.Fragment>
    )
  }

  return <>{props.children}</>
}


export default AuthProtected
