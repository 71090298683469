import { formatDate } from "helpers/Formatters/DateFormatter"
import React from "react"
import { Link } from "react-router-dom"
import { Col, Row } from "reactstrap"

interface BreadCrumbProps {
  title: string,
  pageTitle: string,
  action: string,
  startTime?: any,
  subTitle?: any,
  subAction?: any
}

const BreadCrumb = ({
  title,
  pageTitle,
  action,
  startTime,
  subTitle,
  subAction,
}: BreadCrumbProps) => {
  return (
    <React.Fragment>
      <Row>
        <Col xs={12}>
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <div className="flex gap-2 items-center h-full">
              <h4 className="mb-0">{title}</h4>
              <h5 className="mb-0 text-gray-500">
                {startTime && startTime !== ""
                  ? `| ${formatDate(startTime)}`
                  : ""}
              </h5>
            </div>
            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                  <Link to={action}>{pageTitle}</Link>
                </li>
                {subTitle && (
                  <li className="breadcrumb-item">
                    <Link to={subAction}>{subTitle}</Link>
                  </li>
                )}
                <li className="breadcrumb-item active disabled">
                  {pageTitle !== title && title}
                </li>
              </ol>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default BreadCrumb
