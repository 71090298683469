import React, { useEffect } from 'react';
import withRouter from '../Components/Common/withRouter';

//redux
import { useSelector } from "react-redux";

const NonAuthLayout = ({ children } : any) => {
    // Access the layoutModeType state directly using useSelector
    const layoutModeType = useSelector((state: any) => state.Layout.layoutModeType);

    useEffect(() => {
        if (layoutModeType === "dark") {
            document.body.setAttribute("data-bs-theme", "dark");
        } else {
            document.body.setAttribute("data-bs-theme", "light");
        }
        return () => {
            document.body.removeAttribute("data-bs-theme")
        }
    }, [layoutModeType]);

    return (
        <div>
            {children}
        </div>
    );
};

export default withRouter(NonAuthLayout);
