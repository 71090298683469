import BreadCrumb from "Components/Common/BreadCrumb"
import { formatDate } from "helpers/Formatters/DateFormatter"
import { FormatName } from "helpers/Formatters/Format"
import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"
import { ZERO_OUT_TIMELINE } from "slices/constants/constants"
import { determineBaseUrlAndPerformApiCall } from "slices/interceptor/interceptor"

const TimelineReport = () => {
  const [timelineData, setTimelineData] = useState<any>()
  const [currentIndex, setCurrentIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const location = useLocation()
  const [transactionID, setTransactionID] = useState()
  const state = location?.state

  useEffect(() => {
    if (state) {
      setTransactionID(state?.key?.transaction_id)
    }
  }, [])

  useEffect(() => {
    if (transactionID) {
      const getSummary = async () => {
        setIsLoading(true)

        await determineBaseUrlAndPerformApiCall(
          `${ZERO_OUT_TIMELINE}/${transactionID}`,
          "GET"
        ).then((response: any) => {
          setTimelineData(response)
          setIsLoading(false)
        })
      }

      getSummary()
    }
  }, [transactionID])

  return (
    <React.Fragment>
      {isLoading ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : (
        <div className="page-content">
          <Container fluid>
          <BreadCrumb
              title={`${FormatName(state?.key?.type)} Timeline`}
              pageTitle="Report"
              action="/zero-out-summary-report"
            />
            <Row className="mt-4">
              <Col lg={12}>
                <div className="w-full flex justify-center flex-col items-center gap-2">
                  <p className="font-bold text-primary m-0 p-0">Total Amount</p>
                  <div className="bg-white rounded-md px-28 py-2 flex justify-center items-center w-fit">
                    <p className="font-bold text-blue-500 p-0 m-0">
                      $ {timelineData?.total_amount}
                    </p>
                  </div>
                  <div className="grid grid-cols-2 gap-2">
                    <p className="p-0 m-0 font-bold text-right">Debtor Name:</p>
                    <p className="m-0 p-0">{timelineData?.debtor_name}</p>
                    <p className="p-0 m-0 font-bold">Inbound Bundle ID:</p>
                    <p className="p-0 m-0">{timelineData?.inbound_bundle_id}</p>
                  </div>
                </div>

                <section className="ps-timeline-sec">
                  <div className="container">
                    <ol className="ps-timeline">
                      {timelineData?.statuses?.map((item: any, index: any) => (
                        <li key={index}>
                          <div
                            className={`${
                              index % 2 === 0
                                ? "img-handler-top"
                                : "img-handler-bot"
                            }`}
                          >
                            <div className="flex items-center flex-col">
                              <div className="flex flex-col bg-white items-center rounded-md px-2 py-1 min-w-44 lg:min-w-52  mr-6">
                                <p className="m-0 p-0 font-bold">
                                  {formatDate(item?.date)}
                                </p>
                                <p className="m-0 p-0 capitalize border-2 rounded-md border-primary flex w-fit justify-center font-bold">
                                  {item?.status}
                                </p>
                              </div>
                            </div>
                          </div>
                          <span
                            className={`${
                              index % 2 === 0 ? "ps-sp-top" : "ps-sp-bot"
                            } cursor-pointer`}
                            onClick={() => {
                              setIsOpen(true)
                              setCurrentIndex(index)
                            }}
                          />
                        </li>
                      ))}
                    </ol>
                  </div>
                </section>
                <div
                  className={`absolute w-60 h-60 lg:w-64 lg:h-64 flex flex-col items-center bg-white p-2 rounded-full shadow-2xl border shadow-blue-400 top-[22rem] justify-center transform transition-all ease-in ${
                    isOpen ? "scale-100 opacity-100" : "scale-0 opacity-0 -z-10"
                  }`}
                  style={{
                    left: `calc(50% + ${currentIndex}px)`,
                    boxShadow: "0px 8px 16px rgba(76, 161, 174, 0.4)",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  {/* Circular Grid Layout */}
                  <div className="flex flex-col items-center justify-center p-4 rounded-full  gap-3">
                    <div className="text-center text-lg font-bold text-[#495057]">
                      Status
                    </div>
                    <div className="text-center capitalize text-blue-500 font-semibold border-2 border-[#2A9CDB] bg-[#DFF0FA] w-fit h-fit rounded-lg px-2">
                      {timelineData?.statuses[currentIndex]?.status}
                    </div>
                    <div className="text-center text-primary text-xs lg:text-sm border-2 border-[#4CA1AE80] bg-[#F3F3F8] w-fit h-fit rounded-lg px-2 py-1">
                      {formatDate(timelineData?.statuses[currentIndex]?.date)}
                    </div>
                  </div>
                  {/* Previous Arrow */}
                  <button
                    onClick={() => setCurrentIndex(currentIndex - 1)}
                    className={`${
                      currentIndex === 0 && "bg-[#6a8daa] hover:bg-[#6a8daa]"
                    } rounded bg-[#31506A] cursor-pointer hover:bg-[#2b435a] px-2 py-1 mt-2 absolute left-0 bottom-24 transform 
                        md:-translate-x-10 md:-translate-y-6 md:rotate-0
                        translate-x-[15.2rem] -translate-y-10 rotate-90`}
                    disabled={currentIndex === 0}
                  >
                    <i className="ri-arrow-left-line text-white text-lg" />
                  </button>

                  <button
                    onClick={() => setIsOpen(!isOpen)}
                    className={`rounded-full bg-red-200 cursor-pointer hover:bg-red-300 hover:text-red-500 px-2 py-1 absolute transform 
                          md:translate-x-24 -translate-y-24
                          translate-x-20
                          `}
                  >
                    <i className="ri-close-line text-red-600 text-xl" />
                  </button>

                  {/* Next Arrow */}
                  <button
                    onClick={() => setCurrentIndex(currentIndex + 1)}
                    className={`${
                      currentIndex === timelineData?.statuses?.length - 1 &&
                      "bg-[#6a8daa] hover:bg-[#6a8daa]"
                    } rounded bg-[#31506A] cursor-pointer hover:bg-[#2b435a] px-2 py-1 mt-2 transform 
                        md:translate-x-[9.5rem] md:-translate-y-24 md:rotate-0
                        translate-x-36 -translate-y-16 rotate-90`}
                    disabled={
                      currentIndex === timelineData?.statuses?.length - 1
                    }
                  >
                    <i className="ri-arrow-right-line text-white text-lg" />
                  </button>
                </div>
                <div
                  className={`mb-3 flex justify-center transform transition-all ease-in absolute -mt-64 items-center md:relative md:mt-0 w-full ${
                    isOpen ? "opacity-100 scale-100" : "opacity-0 scale-0 -z-10"
                  }`}
                >
                  <div
                    className="flex flex-col md:grid md:grid-cols-4 bg-white rounded-2xl px-4 py-2 md:w-fit w-3/5"
                    style={{
                      boxShadow: "0px 8px 16px rgba(76, 161, 174, 0.4)",
                    }}
                  >
                    <p className="font-bold p-0 m-0">Bank Name:</p>
                    <p>
                      {timelineData?.statuses[currentIndex]?.details?.bank_name}
                    </p>
                    <p className="font-bold p-0 m-0">Account Number:</p>
                    <p>
                      {
                        timelineData?.statuses[currentIndex]?.details
                          ?.account_number
                      }
                    </p>
                    <p className="font-bold p-0 m-0">Bank Number:</p>
                    <p>
                      {timelineData?.statuses[currentIndex]?.details?.bank_name}
                    </p>
                    {timelineData?.statuses[currentIndex]?.details
                      ?.outbound_bundle_id && (
                      <p className="font-bold p-0 m-0">Outbound Bundle ID</p>
                    )}
                    {timelineData?.statuses[currentIndex]?.details
                      ?.outbound_bundle_id && (
                      <p>
                        {
                          timelineData?.statuses[currentIndex]?.details
                            ?.outbound_bundle_id
                        }
                      </p>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </React.Fragment>
  )
}

export default TimelineReport
