
import { GET_WORKFLOW_ACTIONS } from "slices/constants/constants";
import { determineBaseUrlAndPerformApiCall } from "slices/interceptor/interceptor";
import { fetchActionsSuccess, fetchActionsFailure, fetchActionsStart } from "./reducer";

export const fetchActions = () => async (dispatch) => {
    dispatch(fetchActionsStart())
    
    try {
        const response = await determineBaseUrlAndPerformApiCall(`${GET_WORKFLOW_ACTIONS}`, 'GET')
        if (response.error && response.error.code === 'NotFound') {
            dispatch(fetchActionsSuccess([]));
        }
        else {
            dispatch(fetchActionsSuccess(response));
        }
    }
    catch (error) {
        dispatch(fetchActionsFailure(error.message))
    }
}