import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { fetchTickets } from 'slices/tickets/thunk'
import { getStatisticsByWorkflowId } from 'slices/workflow/statistics/thunk'
import { fetchWorkflows } from 'slices/workflow/thunk'
import { STATUS_COMPLETED, STATUS_IN_PROGRESS, STATUS_PENDING, TASK_CREATE_BUNDLE } from 'slices/constants/constants'
// imports reusable components
import {
  Card, CardBody, CardHeader, Col, Container, Row
} from 'reactstrap'
import { toast, ToastContainer } from 'react-toastify'
import { formatTime, formatDate } from 'helpers/Formatters/DateFormatter'

// import Components
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import {
  getSessionStorageData
} from 'helpers/Formatters/Format'
import { TicketListing } from 'pages/Tickets/Listing'
import { determineBaseUrlAndPerformApiCall } from 'slices/interceptor/interceptor'
import { TaskCardContent } from './TaskCard'
import { Slider } from 'Components/Common/Slider/SliderLayout'
import { renderTaskStatistics, TaskActionContent } from 'Components/Common/Slider/Content/TaskActionContent'
import { TaskEscalationContent } from 'Components/Common/Slider/Content/TaskEscalationContent'
import { getWorkflowById, handleStartButtonClick, handleTicketEsclations } from 'helpers/Workflow/helper'

const InboundPaymentProcessing = ({ data: propsData }) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const state = location?.state || propsData

  const [stats, setStats] = useState([])
  const [tasks, setTasks] = useState([])
  const [workflowStatus, setWorkflowStatus] = useState("")
  const [ticketsData, setTicketsData] = useState([])
  const [openTab, setOpenTab] = useState({ open: false })
  const [openEscalation, setOpenEscalation] = useState({ open: false, disabled: false })
  const [startTime, setStartTime] = useState("")

  const ticketTitle = useRef(null)
  const ticketSubject = useRef(null)

  const { tickets, loadingTickets } = useSelector((state) => state.Ticket)
  const { workflows, loading } = useSelector((state) => state.Workflow)
  const { statistics, statisticsLoading } = useSelector((state) => state.Statistics)

  document.title = `${state?.key?.name} | TFS Processing App`

  // Utility to get tasks by workflow ID
  const getTasksByWorkflowId = (workflows, targetId) => {
    const workflow = getWorkflowById(workflows, targetId)
    return workflow ? workflow?.tasks : []
  }

  // To run ONLY once on page load
  useEffect(() => {
    if (state) {
      // Get the latest workflows from the DB
      dispatch(fetchWorkflows())

      // Set the Workflow's StartTime used in Workflow Timer and Calc of Current Date
      setStartTime(state?.key?.started_time)

      // Set the Workflow's Status used in Workflow Timer and Tasks
      setWorkflowStatus(state?.key?.status)

      // Load the Statistics for the Workflow
      const targetId = state?.key?.workflow_id
      const matchingWorkflow = getWorkflowById(workflows, targetId)
      dispatch(getStatisticsByWorkflowId(targetId, matchingWorkflow))

      // Load the Tickets under the selected workflow
      dispatch(fetchTickets())

      // Update the Tasks if anything's changed with Data from the SSE/ DB
      const newTasks = state?.key?.tasks
      const updatedTasks = newTasks.map((task, index) => {
        // Initialize the task with a loading flag set to false by default
        const updatedTask = { ...task, isLoading: false }

        // Check if the current task is 'pending' and the previous task is 'completed'
        if (task?.status === STATUS_PENDING && index > 0 && tasks[index - 1]?.status === STATUS_COMPLETED) {
          updatedTask.isLoading = true
        }

        return updatedTask
      })
      setTasks(updatedTasks)
    }
  }, [])

  useEffect(() => {
    if (workflows && !loading && state) {
      const targetId = state?.key?.workflow_id
      const newTasks = getTasksByWorkflowId(workflows, targetId)

      // Only update tasks if they have changed
      if (JSON.stringify(newTasks) !== JSON.stringify(tasks)) {
        // Check if the previous task was completed
        // Array to hold tasks with a loading indicator flag
        const updatedTasks = newTasks.map((task, index) => {
          // Initialize the task with a loading flag set to false by default
          const updatedTask = { ...task, isLoading: false }

          // Check if the current task is 'pending' and the previous task is 'completed'
          if (task?.status === STATUS_PENDING && index > 0 && newTasks[index - 1]?.status?.toLowerCase() === STATUS_COMPLETED) {
            updatedTask.isLoading = true
          }

          const isFailed = getSessionStorageData(`restarted_${task?.id}`)
          const isEscalated = getSessionStorageData(`escalated_${task?.id}`)

          if (task?.status.toLowerCase() === STATUS_IN_PROGRESS && isFailed) {
            sessionStorage.setItem(`started_${task?.id}`, JSON.stringify(false))
            sessionStorage.setItem(`restarted_${task?.id}`, JSON.stringify(false))
          }

          if (task?.status.toLowerCase() === STATUS_PENDING && isEscalated && index > 0) {
            sessionStorage.setItem(`started_${newTasks[index - 1]?.id}`, JSON.stringify(false))
            sessionStorage.setItem(`escalated_${task?.id}`, JSON.stringify(false))
          }

          return updatedTask
        })

        setTasks(updatedTasks)

      }

      // Get Worklfow's Updated StartTime and Status
      const matchingWorkflow = getWorkflowById(workflows, targetId)
      if (matchingWorkflow) {
        setStartTime(matchingWorkflow?.start_time)
        setWorkflowStatus(matchingWorkflow?.status)
      }

      const foundTask = newTasks.find(task => task?.key_name === TASK_CREATE_BUNDLE)
      if (foundTask && foundTask.status?.toLowerCase() === STATUS_COMPLETED) {
        const foundTaskArray = Array.isArray(foundTask?.result) ? foundTask?.result : [foundTask?.result]
        setStats((prevStats) => (prevStats !== foundTaskArray ? foundTaskArray : prevStats))
      }
    }
  }, [workflows, loading, state])

  useEffect(() => {
    if (statistics && !statisticsLoading) {
      setStats(statistics)
    }
  }, [statistics, statisticsLoading])

  useEffect(() => {
    // Call Getstatistics API
    if (state) {
      const targetId = state?.key?.workflow_id
      const matchingWorkflow = getWorkflowById(workflows, targetId)

      dispatch(getStatisticsByWorkflowId(targetId, matchingWorkflow))
    }

    if (state?.key?.tasks) {

      const newTasks = state?.key?.tasks
      const updatedTasks = newTasks.map((task, index) => {
        // Initialize the task with a loading flag set to false by default
        const updatedTask = { ...task, isLoading: false }

        // Check if the current task is 'pending' and the previous task is 'completed'
        if (task?.status === STATUS_PENDING && index > 0 && tasks[index - 1]?.status === STATUS_COMPLETED) {
          updatedTask.isLoading = true
        }

        return updatedTask
      })

      setTasks(updatedTasks)
    }
  }, [state])

  useEffect(() => {
    if (tickets && !loadingTickets) {
      const targetId = state?.key?.workflow_id
      const matchingTickets = ((tickets, targetId) => {
        return tickets.filter(ticket => ticket.workflow_id === targetId)
      })(tickets, targetId)

      const result = matchingTickets?.map((item => {
        return {
          ticketID: item?.ticket_id,
          workflowName: item?.workflow_name,
          workflowID: item?.workflow_id,
          taskName: item?.task_name,
          subject: item?.error?.subject,
          createAt: item?.created_at !== "" ? formatDate(item?.created_at) + " ," + formatTime(item?.created_at) : "",
          status: item?.ticket_status,
          priority: item?.priority,
          url: item?.ticket_url
        }
      }))

      setTicketsData(result)
    }
  }, [loadingTickets, tickets, state])


  const handleTaskRestartButtonClick = async (taskId, taskName) => {
    setOpenTab({ open: false })

    // Save the disabled state
    sessionStorage.setItem(`restarted_${taskId}`, JSON.stringify(true))

    const pathUri = `restart_${taskName}_task`

    const body = JSON.stringify({
      [`${taskName}_task_id`]: taskId
    })

    await determineBaseUrlAndPerformApiCall(`/inbound/${pathUri}`, 'POST', body).then((response) => {
      if (!response.error) {
        toast.success("Task Restarted Sucessfully !", {
          position: "top-center"
        })
      }
      if (response.error) {
        toast.error(response.error.message, {
          position: "top-center"
        })
      }
    }).catch((errorResp) => {
      toast.error(errorResp, {
        position: "top-center"
      })
    })
  }

  const handleEscalate = () => {
    setOpenTab({ open: false })
    setOpenEscalation({
      open: true,
      id: openTab.taskId,
      taskKeyName: openTab?.actionKeyName,
      esclationUrl: openTab?.esclationUrl,
      actionName: openTab?.actionName,
    })
  }

  const handleConfirm = () => {
    if (openTab.label === "Restart") {
      handleTaskRestartButtonClick(openTab.taskId, openTab.actionKeyName)
    } else {
      handleStartButtonClick({
        taskId: openTab.taskId,
        actionPath: openTab.actionPath,
        actionKeyName: openTab.actionKeyName,
        setOpenTab: setOpenTab
      })
    }
  }

  const handleTicketConfirmation = () => {
    handleTicketEsclations({
      taskId: openEscalation?.id,
      actionKeyName: openEscalation?.taskKeyName,
      setOpenTab: setOpenTab,
      setOpenEscalation: setOpenEscalation,
      ticketTitle: ticketTitle,
      ticketSubject: ticketSubject,
      workflowId: state?.key?.workflow_id,
      workflows: workflows
    })
  }

  return (
    <React.Fragment>
      {/* Toaster Container for Messages in the Page */}
      <ToastContainer />
      <div className="page-content" id="processingContent">

        {/* Slider for Ticket Escalation */}
        <Slider isOpen={openEscalation.open} setOpen={setOpenEscalation} actionName={openEscalation.actionName}>
          <TaskEscalationContent
            ticketTitle={ticketTitle}
            ticketSubject={ticketSubject}
            setOpenEscalation={setOpenEscalation}
            handleTicketConfirmation={handleTicketConfirmation}
          />
        </Slider>

        {/* Slider for Manual Action */}
        <Slider isOpen={openTab.open} setOpen={setOpenTab} actionName={openTab.actionName}>
          <TaskActionContent
            openTab={openTab}
            setOpenTab={setOpenTab}
            stats={stats}
            handleEscalate={handleEscalate}
            handleConfirm={handleConfirm}
            tasks={tasks}
            workflowId={state?.key?.workflow_id}
          />
        </Slider>

        <Container fluid>
          {/* Page Heading */}
          <BreadCrumb title={state?.key?.name} pageTitle="Workflow" action="/workflow" startTime={startTime} />

          {/* Workflow's Task List */}
          <Row>
            <Col lg={12}>
              <Card className='border-none'>
                <TaskCardContent
                  startTime={startTime}
                  allocatedTime={state?.key?.allocatedTime}
                  status={workflowStatus}
                  tasks={tasks}
                  openTab={openTab}
                  setOpenTab={setOpenTab}
                />
              </Card>
            </Col>
          </Row>

          {/* Workflow Statistics */}
          {renderTaskStatistics(tasks, stats)}

          {/* Workflow Tickets */}
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">TICKETS</h5>
                </CardHeader>
                <CardBody>
                  <TicketListing tickets={ticketsData} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment >
  )
}

export default InboundPaymentProcessing
