import BreadCrumb from "Components/Common/BreadCrumb"
import React, { useEffect, useRef, useState } from "react"
import DatePicker from "react-datepicker"
import { useLocation } from 'react-router-dom'
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import { DateTime } from "ts-luxon"
import classnames from "classnames"
import {
  DEBIT_CARD,
  INBOUND_ACH,
  MONEY_GRAM,
  WAGE_DEDUCTION,
  ZERO_OUT_INBOUND_REPORT,
  ZERO_OUT_INBOUND_REPORT_PAGE_SIZE,
} from "slices/constants/constants"
import { determineBaseUrlAndPerformApiCall } from "slices/interceptor/interceptor"
import { toast } from "react-toastify"
import { TransactionDetails } from "./TransactionTypes"
import { formatDate } from "helpers/Formatters/DateFormatter"

const InboundDaySummary = () => {
  document.title = "Inbound Day Summary | TFS Processing App"
  const [loading, setLoading] = useState(true)
  const [selectedDate, setSelectedDate] = useState<Date | null>(null)
  const datePickerRef = useRef<any>(null)
  const todayFormatted = DateTime.now()
    .setZone("America/New_York")
    .toFormat("MM-dd-yyyy")
  const [details, setDetails] = useState<any>({})
  const [activeTab, setActiveTab] = useState<string>(INBOUND_ACH)
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1)

  const location = useLocation()
  const state = location?.state 

  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  useEffect(() => {
    if (state) {
      const formattedDate = DateTime.fromFormat(state?.inboundDate, 'MM-dd-yyyy').toJSDate();
      setSelectedDate(formattedDate)
    }
  }, [])

  useEffect(() => {
    if (state) {
      const formattedDate = DateTime.fromFormat(state?.inboundDate, 'MM-dd-yyyy').toJSDate();
      setSelectedDate(formattedDate)
    }
  }, [state])

  const handlePreviousDate = () => {
    setSelectedDate((prevDate) => {
      const date = prevDate ? new Date(prevDate) : new Date()
      date.setDate(date.getDate() - 1)
      return date
    })
  }

  const handleNextDate = () => {
    setSelectedDate((prevDate) => {
      const date = prevDate ? new Date(prevDate) : new Date()
      date.setDate(date.getDate() + 1)
      return date
    })
  }

  const handleIconClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true)
    }
  }

  useEffect(() => {
    if (selectedDate) {
      setLoading(true)
      const year = selectedDate.getFullYear()
      const month = (selectedDate.getMonth() + 1).toString().padStart(2, "0") // Months are zero-indexed
      const day = selectedDate.getDate().toString().padStart(2, "0")
      const formattedSelectedDate = `${year}-${month}-${day}`

      const getSummary = async () => {
        try {
          await determineBaseUrlAndPerformApiCall(
            `${ZERO_OUT_INBOUND_REPORT}date=${formattedSelectedDate}&payment_type=${activeTab}&page_no=${currentPageNumber}&page_size=${ZERO_OUT_INBOUND_REPORT_PAGE_SIZE}`,
            "GET"
          )
            .then((response: any) => {
              // Bascially loop through transactions, and form an array
              // Set that to the summary data
              setDetails(response)
              setLoading(false)
            })
            .catch((errorResp) => {
              toast.error(errorResp, { position: "top-center" })
              console.error(errorResp)
            })
        } catch (error: any) {
          toast.error(error?.message, { position: "top-center" })
          console.error(error)
        }
      }
      getSummary()
    }
  }, [selectedDate, activeTab, currentPageNumber])

  return (
    <React.Fragment>
      {loading ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : (
        <div className="page-content">
          <Container fluid>
            <BreadCrumb
              title="Inbound Day Summary"
              pageTitle="Report"
              action="/inbound-day-summary"
              subTitle="Zero Out Summary Report"
              subAction="/zero-out-summary-report"
            />
            {/* Date Picker */}
            <div className="relative flex items-center justify-center mb-4">
              <div className="flex flex-col items-center gap-2">
                <p className="text-gray-700 font-semibold m-0 p-0">
                  Inbound Day
                </p>
                <div className="flex items-center gap-x-4">
                  {/* Left Arrow */}
                  <div
                    onClick={handlePreviousDate}
                    className="rounded bg-[#31506A] cursor-pointer hover:bg-[#2b435a] px-2"
                  >
                    <i className="ri-arrow-left-line text-white text-lg" />
                  </div>
                  {/* Date Picker */}
                  <div className="relative inline-flex items-center w-auto justify-centre">
                    <DatePicker
                      className="border-1 border-black-500 rounded-md font-bold placeholder:text-black  h-8 pl-4 text-sm w-36"
                      selected={selectedDate}
                      onChange={(date) => setSelectedDate(date)}
                      dateFormat="MM-dd-yyyy"
                      placeholderText={todayFormatted}
                      ref={datePickerRef}
                    />
                    <i
                      className="ri-calendar-2-line absolute right-5 bottom-0.5 text-gray-500 text-lg cursor-pointer"
                      onClick={handleIconClick}
                    />
                  </div>
                  {/* Right Arrow */}
                  <div
                    onClick={handleNextDate}
                    className="rounded bg-[#31506A] cursor-pointer hover:bg-[#2b435a] px-2"
                  >
                    <i className="ri-arrow-right-line text-white text-lg" />
                  </div>
                </div>
              </div>
              <div className="hidden top-1 md:flex flex-col gap-2 absolute right-0 text-right">
                <p className="font-semibold text-gray-700 m-0 p-0">
                  As of End of Day:
                </p>
                <p className="font-bold m-0 p-0">{formatDate(state?.asOfEndOfDay)}</p>
              </div>
            </div>

            <Row className="relative">
              <Col>
                <Card>
                  <CardBody>
                    {/* Action Tabs */}
                    <Nav
                      fill
                      tabs
                      className="nav-tabs-custom2 nav-success mb-3"
                    >
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === INBOUND_ACH,
                          })}
                          onClick={() => toggle(INBOUND_ACH)}
                          style={{ cursor: "pointer" }}
                        >
                          ACH
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === MONEY_GRAM,
                          })}
                          onClick={() => toggle(MONEY_GRAM)}
                          style={{ cursor: "pointer" }}
                        >
                          Money Gram
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === WAGE_DEDUCTION,
                          })}
                          onClick={() => toggle(WAGE_DEDUCTION)}
                          style={{ cursor: "pointer" }}
                        >
                          Wage Deduction
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === DEBIT_CARD,
                          })}
                          onClick={() => toggle(DEBIT_CARD)} // Fixed index for consistency
                          style={{ cursor: "pointer" }}
                        >
                          Debit Card
                        </NavLink>
                      </NavItem>
                    </Nav>

                    {/* Action Tab Items */}
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId={INBOUND_ACH}>
                        <TransactionDetails
                          transDetails={details}
                          type={activeTab}
                          currentPageNumber={currentPageNumber}
                          setCurrentPageNumber={setCurrentPageNumber}
                        />
                      </TabPane>
                      <TabPane tabId={MONEY_GRAM}>
                        <TransactionDetails
                          transDetails={details}
                          type={activeTab}
                          currentPageNumber={currentPageNumber}
                          setCurrentPageNumber={setCurrentPageNumber}
                        />
                      </TabPane>
                      <TabPane tabId={WAGE_DEDUCTION}>
                        <TransactionDetails
                          transDetails={details}
                          type={activeTab}
                          currentPageNumber={currentPageNumber}
                          setCurrentPageNumber={setCurrentPageNumber}
                        />
                      </TabPane>
                      <TabPane tabId={DEBIT_CARD}>
                        <TransactionDetails
                          transDetails={details}
                          type={activeTab}
                          currentPageNumber={currentPageNumber}
                          setCurrentPageNumber={setCurrentPageNumber}
                        />
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </React.Fragment>
  )
}

export default InboundDaySummary
