import React, { useEffect } from 'react';
import { Card, CardBody, Col, Container, Row, Button } from 'reactstrap';
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { jwtDecode } from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { fetchWorkflows } from 'slices/workflow/thunk';
import { loginSuccess } from 'slices/auth/login/reducer';
//redux

import { Link, useNavigate } from "react-router-dom";
import withRouter from "../../Components/Common/withRouter";

import logoLight from "../../assets/images/tfs-icon.png";
import { useMsal, useAccount } from '@azure/msal-react';
import { fetchTickets } from 'slices/tickets/thunk';


const Login = () => {
    const dispatch = useDispatch()
    const { instance, accounts } = useMsal();

    const account = useAccount(accounts[0] || {});

    const login = async () => {
        try {
            const scope = window.env.REACT_APP_API_SCOPE

            await instance.loginPopup({
                prompt: 'consent',
                scopes: [scope], // Specify the scopes you need
            });
        } catch (e) {
            console.error('Login error:', e);
        }
    };

    const history = useNavigate()
    useEffect(() => {
        const acquireToken = async () => {
            if (account) {
                const scope = window.env.REACT_APP_API_SCOPE

                try {
                    const response = await instance.acquireTokenSilent({
                        scopes: [scope], // Specify the scopes you need
                        account: account
                    });

                    if (response) {
                        const { accessToken, idToken } = response;
                        sessionStorage.setItem('authToken', accessToken);
                        sessionStorage.setItem('idToken', idToken);

                        // Set Profile Details
                        const decodedToken = jwtDecode(idToken);
                        sessionStorage.setItem("username", decodedToken?.name)
                        dispatch(fetchWorkflows());
                        dispatch(fetchTickets())
                        dispatch(loginSuccess());

                        history('/workflow')
                    }
                } catch (error) {
                    console.error('Token acquisition error:', error);
                    // Optionally handle token acquisition failure here
                }
            }
        };

        acquireToken();
    }, [account, instance]);

    document.title = "Login | TFS Processing App";
    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="auth-page-content">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <div>
                                        <Link to="/" className="d-inline-block auth-logo">
                                            <img src={logoLight} alt="" className='h-16' />
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">
                                    <CardBody className="p-4">
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">Welcome Back !</h5>
                                            <p className="text-muted">Sign in to continue to TFS Processing App.</p>
                                        </div>
                                        <div className="p-2 mt-4">
                                            <Button
                                                className="btn btn-primary w-100" type="submit" onClick={login}>
                                                <i className="ri-microsoft-fill fs-16" />
                                                Sign In with Microsoft
                                            </Button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default withRouter(Login);