
import React, { useMemo } from 'react'
// import reusable components
import TableContainer from 'Components/Common/TableContainerReactTable'

export const GetStatiscs = ({ statistics }: any) => {
    const columns = useMemo(
        () => [
            {
                header: "Total Transactions",
                accessorKey: "total_transactions",
                enableColumnFilter: false,
            },
            {
                header: "Total Amount",
                accessorKey: "total_amount",
                enableColumnFilter: false,
            },
            {
                header: "Total Fees",
                accessorKey: "total_fees",
                enableColumnFilter: false,
            },
            {
                header: "Refund Count",
                accessorKey: "refund_count",
                enableColumnFilter: false,
            },
            {
                header: "Refund Fee Amount",
                accessorKey: "refund_fee_amount",
                enableColumnFilter: false,
            }
        ],
        []
    )

    return (
        <React.Fragment >
            <TableContainer
                columns={(columns || [])}
                data={(statistics || [])}
                customPageSize={5}
                SearchPlaceholder='Search Products...'
                showPagination={false}
                clickable={false}
            />
        </React.Fragment >
    )
}