import { createSlice } from "@reduxjs/toolkit";

// Initial state
const initialState = {
    tickets: [], // Single global state to hold all tickets
    loadingTickets: false,
    error: null,
};

const ticketSlice = createSlice({
    name: "ticket",
    initialState,
    reducers: {
        fetchTicketsStart: (state) => {
            state.loadingTickets = true; // Set loading to true when the fetch starts
        },
         // Action to update workflows from SSE data
         updateTickets: (state, action) => {
            const updatedTickets = action.payload; // This should be an array of updated ticket objects
            // Iterate over the updated tickets
            updatedTickets.forEach((updatedTicket) => {
                
                const existingTicketIndex = state.tickets.findIndex(ticket => ticket.ticket_id === updatedTicket.ticket_id);

                if (existingTicketIndex !== -1) {
                    // Update the existing ticket in the array
                    
                    state.tickets[existingTicketIndex] = {
                        ...state.tickets[existingTicketIndex], // Merge existing ticket properties
                        ...updatedTicket // Merge updated properties
                    };
                }else{
                    state.tickets.push(updatedTicket);
                    state.tickets.sort((a, b) => b.ticket_id - a.ticket_id);
                }
            });
        },
        fetchTicketsSuccess: (state,action) => {
            state.tickets = action.payload; // Store all tickets in the global state
            state.loadingTickets = false;
        },
        fetchTicketsFailure: (state,action) => {
            state.loadingTickets = false;
            state.error = action?.payload;
        }
    },
})

// Export actions and reducer
export const { updateTickets, fetchTicketsSuccess, fetchTicketsFailure, fetchTicketsStart } = ticketSlice.actions;
export default ticketSlice.reducer;
